import LibroFormView from '@/modules/Libro/views/LibroFormView.vue';
import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: '',
    redirect: "/intranet/lista-reclamos",
    meta:{
      requiresAuth: true,
      title: 'HOME'
    }
  },

  {
    path: '/librodereclamaciones',
    name: 'libroreclamacionesnew',
    component: LibroFormView,
    meta:{
      title: 'LIBRO DE RECLAMACIONES'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
  var IsLoggeIn = store.getters.getterLogginIn;
  document.title = "INTRANET | " + to.meta.title;
  if (requiresAuth && !IsLoggeIn) {
    next("/login");
  } else if (requiresGuest && IsLoggeIn) {
    next("/");
  } else {
    next();
  }
});


export default router
