import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';


import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
})

import LibroModule from './modules/Libro'
import UsuariosModule from './modules/Usuarios'
import { registerModules } from './register-modules'
registerModules({
    libro:LibroModule,
    usuarios:UsuariosModule
});


import abillitymixin from './services/abilitymixin'


createApp(App).use(vuetify).use(store).use(router).use(abilitiesPlugin, ability).mixin(abillitymixin).mount('#app')
