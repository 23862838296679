import silderRight from '@/modules/Shared/store/silderRight';
import { createStore } from 'vuex'

import VuexPersistence from 'vuex-persist' 

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user }),
}); 

export default createStore({
  plugins: [ 
    vuexLocal.plugin,
  ], 
  state: {
    user:
    {
      email: "",
      password: "",
      token: ""
    },
    userDefault:
    {
      email: "",
      password: "",
      token: ""
    },

    overlayLogo:false
  },
  getters: {
    getterLogginIn(state) { 
      return state.user != null && state.user.token;
    },
    getterToken(state)
    {
      return state.user.token;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    silderRight
  }
})
