import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class AuthService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:{
                Accept: "application/json",
                'Content-Type' : "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async switchActiveUser(iduser)
    {
        const response = await this.axios.put('/auth?iduser=' + iduser)
        return response.data;
    }

    async showUser(iduser)
    {
        const response = await this.axios.get('/showuser?iduser='+iduser)
        return response.data;
    }

    async login(data)
    {
        const response = await this.axios.post('/login', data)
        return response.data;
    }

    async logout(data)
    {
        const response = await this.axios.post('/logout', data)
        return response.data;
    }

    async getsendemailrestorepass(email)
    {
        const response = await this.axios.get('restore-pass', {params:{email: email}})
        return response.data;
    }

    async validatetoken(emailencrypted)
    {
        const response = await this.axios.get('validate-token', {params:{emailencrypted: emailencrypted}})
        return response.data;
    }
}