import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class RegistrarReclamoService
{
    constructor()
    {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:{
                Accept: "application/json",
                'Content-Type' : "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }
    async get(params)
    {
        const response = await this.axios.get('/reclamo', {params: params})
        return response.data;
    }

    async create(data)
    {
        const response = await this.axios.post('/reclamo', data)
        return response.data;
    }

    async cambiarEstado(data)
    {
        const response = await this.axios.post('/cambiar-estado-reclamo', data)
        return response.data;
    }

    async cerrarReclamo(data)
    {
        const response = await this.axios.post('/cerrar-reclamo', data)
        return response.data;
    }

    async generarPDF(data)
    {
 
        const response = await this.axios.post('/generar-pdf', data, {
            responseType: 'arraybuffer',
        })
        
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        link.download = 'reclamo.pdf';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        return response.data;

    }
}