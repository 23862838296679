import store from "@/store";
import AuthService from "../services/AuthSevice";
import router from "@/router";

const Auth = new AuthService;
export default {
    namespaced: true,
    state: {
        loginForm: {
            email: "",
            password: ""
        },
        loadingLogin: false,
        errorLogin:{
            type: "",
            message: ""
        },
        errorLoginDefault:{
            type: "",
            message: ""
        }
    },
    mutations: {
        setUser(state, payload) {
            store.state.user = payload
        },
        resetUser(state) {
            store.state.user = Object.assign({}, store.state.userDefault)
            location.reload();
        },

        renderError(state, errorData) {
            let type, message, status;

            status = typeof errorData === 'object' ? errorData.response.status : 0;

            if (status === 501) {
                type = "warning";
            }
            else if (status === 502) {
                type = "error";
            }
            else {
                type = "info"
            }


            state.errorLogin.type = type
            state.errorLogin.message = typeof errorData === 'object' ? errorData.response.data.error : errorData
        },

        resetError(state) {
            state.errorLogin = Object.assign({}, state.errorLoginDefault)
        },

        badErrorAuth(state, error) {
            console.log(error);
            if (error.response.status == 401) {
                state.overlay = true;
                store.state.token = null;
                store.state.user = Object.assign({}, store.state.userDefault)
                location.reload();
            }
        },
    },
    actions: {
        async login({ commit, dispatch, state },) {
            state.loadingLogin = true
            store.state.overlayLogo = true
            try {
                const data = await Auth.login(state.loginForm);
                commit('setUser', data)
                router.push({ name: "home" })
                state.loadingLogin = false
                store.state.overlayLogo = false
                commit('resetError')
            }
            catch (error) {
                console.log(error)
                store.state.user = Object.assign({}, store.state.userDefault)
                state.loadingLogin = false
                store.state.overlayLogo = false
                commit('renderError', error)
            }
        },

        async logout({ commit, state }) {
            store.state.overlayLogo = true
            try {
                const data = await Auth.logout(store.state.user);
                commit('resetUser')
                store.state.overlayLogo = false
            }
            catch (error) {
                console.log(error)
                commit('renderError', error)
                commit('badErrorAuth', error)
                store.state.overlayLogo = false
            }
        },
    }
}