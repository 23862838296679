<template>
    <v-container>
        <component :is="sendReclamo"></component>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';
import FormLibro from '../components/RegistrarNuevo/FormLibro.vue';
import ConfirmacionEnvio from '../components/RegistrarNuevo/ConfirmacionEnvio.vue';

export default {
    components:{
        FormLibro,
        ConfirmacionEnvio
    },

    computed:{
        ...mapState('libro/RegistrarReclamo', ['sendReclamo'])
    }
}
</script>