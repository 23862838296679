<template>
  <v-layout>
    <silderRigth></silderRigth>
    <navbar-up v-if="getterLogginIn"></navbar-up>
    <v-main>
      <router-view />
      <v-footer absolute color="grey-darken-4">
        <v-container>
          <div class="d-flex justify-space-between align-center">
            <div>
              Clínica Madre Zoraida - {{  yearCurrent() }}
            </div>
          </div>
        </v-container>
      </v-footer>
      
      <v-overlay v-model="overlayLogo" :opacity="1" scrim="white" style="opacity: 1 !important;" z-index="10000" class="d-flex justify-center align-center overlayCustom">
          <v-img :src="require('@/assets/logo-clinica.png')" width="200" class="animate__animated animate__bounceIn"></v-img>
      </v-overlay>

    </v-main>

  </v-layout>
</template>

<script>
import NavbarUp from '@/modules/Shared/components/navbar-up.vue'
import silderRigth from './modules/Shared/components/silder-rigth.vue';
import { mapGetters, mapState } from 'vuex';
import store from './store';
import filters from './modules/Shared/filters';

export default {
  mixins:[filters],
  components: {
    NavbarUp,
    silderRigth
  },
  computed:{
    ...mapState(['overlayLogo']),
    ...mapGetters(['getterLogginIn'])
  },

  created(){
    if(store.getters.getterLogginIn)
    {
      //Necesario para cargar los roles
      store.commit('usuarios/Auth/setUser', store.state.user)
      
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,400;0,800;1,100&family=Titillium+Web:wght@300;400;700;900&display=swap');

#app {
  font-family: 'Public Sans', sans-serif;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body{
  background-color: #F5F5F5  !important;
}

.swal-level {
  z-index: 999999; /* Ajusta este valor según sea necesario */
}

.overlayCustom .v-overlay__scrim{
  opacity: 1 !important;
}

</style>
