<template>
    <v-navigation-drawer :width="width" location="right" temporary v-model="silderOpen">
        <keep-alive>
            <component v-bind:is="component" v-bind:props="propscomponent"></component>
        </keep-alive>

    </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';


export default {
    data() {
        return {
        }
    },


    computed: {
        ...mapState('silderRight', ['silderRight', 'component', 'width', 'propscomponent']),
        silderOpen: {
            get() {
                return this.silderRight
            },
            set() {
                this.switchSilder()
            }
        }
    },

    methods: {
        ...mapMutations('silderRight', ['switchSilder'])
    }



}
</script>