import store from "@/store";
import axios from "axios";
import moment from "moment/moment";

export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Campo Requerido.",
      requiredSelect: (value) => value.length > 0 || "Campo Requerido",
      sirequired: (value) => value === 'si' || "Campo Requerido",
      counter: (value) => value.length <= 20 || "Max 20 caracteres",
      textmedium: (value) => value.length <= 50 || "Max 50 caracteres",
      textsimple: (value) => value.length >= 5 || "Min 5 caracteres",
      celular: (value) => value.length == 9 || "Número de celular no válido",
      dni: (value) =>
        (value && value.length == 8) || "DNI: Solo debe tener 8 dígitos",
      onlyNumber: (value) => {
        const pattern = /^[0-9]*(\.?)[0-9]+$/;
        return pattern.test(value) || "Solo ingrese numeros";
      },
      mayorcero: (value) =>
        parseInt(value) > 0 || "El monto debe ser mayor que cero",
      notnegative: (value) =>
        parseInt(value) > -1 || "No se aceptan números negativos",
      email: (value) => {
        const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(value) || "Email no válido";
      },
      fechaposterior: (value) => {
        const hoy = moment().format("YYYY-MM-DD");
        if (!value) return "Seleccione una fecha";
        if (moment(value).isBefore(hoy))
          return "La fecha debe ser a partir de hoy";
        return true;
      },
      checkboxRequired: (value) =>
        value.length > 0 || "Debes marcar al menos una opción",
      radioRequired: (value) => {
        if (value.length == 0) {
          return "Campo Requerido.";
        }
        return !!value;
      },
    },

    jefaturas: [
      "ADMINISTRACIÓN Y FINANZAS",
      "ACADÉMICO",
      "CALIDAD",
      "COMERCIAL",
      "OPERACIONES",
      "GERENCIA GENERAL",
    ],

    apikeyTynyMCE: "57tteyqdaz0gi161vpe104utkvlv86vep8pshl3m2lfic4yd",
  }),

  methods: {
    initialNames(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0].charAt(0);
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase();
      }

      return fullname;
    },
    firtsName(value) {
      if (!value) return;
      var separate = value.split(" ");
      return separate[0];
    },
    formatUserName(value) {
      if (value == null) return;
      var separate = value.split(" ");
      var fullname = separate[0] + " ";
      if (separate.length >= 2) {
        fullname += separate[1].charAt(0).toUpperCase() + ".";
      }

      return fullname;
    },
    formatFechaSimple(value) {
      if (!value) return "--/--/--";
      return moment(value).format("DD/MM/YYYY");
    },
    formatMoneda(value) {
      if (!value) return 0;
      return new Intl.NumberFormat("es-PE", {
        style: "decimal",
        currency: "PEN",
        minimumFractionDigits: 2,
      }).format(value);
    },

    formatFecha(value) {
      if (!value) return "--/--/--";
      return moment(value).format("DD MMM YYYY");
    },

    formatFechaCustom(value, format) {
      if (!value) return "--/--/--";
      return moment(value).format(format);
    },

    reduceText(text, long) {
      return text && text.length > long ? text.substring(0, long) : text;
    },
    yearCurrent(format = "") {
      return moment().format(format != "" ? format : "YYYY");
    },

    dateCurrent(format = "") {
      return moment().format(format != "" ? format : "DD MMM YYYY");
    },

    timeCurrent() {
      return moment().format("HH:mm:ss");
    },

    isBefore(fecha) {
      let fechaIngresada = moment(fecha);
      let hoy = moment().format("YYYY-MM-DD HH:mm:ss");
      console.log(fechaIngresada.isBefore(hoy));
      return fechaIngresada.isBefore(hoy);
    },

    ifAfter(fecha) {
      let fechaIngresada = moment(fecha);
      let hoy = moment().format("YYYY-MM-DD HH:mm:ss");
      console.log(fechaIngresada);
      console.log(fechaIngresada.isAfter(hoy));
      return fechaIngresada.isAfter(hoy);
    },

    isEqual(fecha) {
      let fechaIngresada = moment(fecha);
      let hoy = moment();
      return hoy.isSame(fechaIngresada, "day");
    },

    hasMultipleSedes() {
      var sedes = store.state.user.roles.sedes.split(",");
      return sedes.length > 1;
    },

    TypeFileIndent(url) {
      if (!url) {
        return "";
      }
      var spiturl = url.split(".");
      return spiturl[spiturl.length - 1];
    },

    isToday(fecha) {
      const hoy = moment().format("YYYY-MM-DD");
      let fechaComparar = moment(fecha);
      return fechaComparar.isSame(hoy, "day");
    },
    isFinDeSemana() {
      const fechaActual = moment();
      const diaSemana = fechaActual.day();
      const esFinDeSemana = diaSemana === 0 || diaSemana === 6;

      if (esFinDeSemana) {
        return true
      } else {
        return false
      }
    },
    isNull(value) {
      return !value ? "" : value;
    },

    isMobil() {
      return this.$vuetify.breakpoint.x;
    },

    capitalizeWords(inputString) {
      if (inputString == "") {
        return;
      }
      const words = inputString.split(" ");
      const capitalizedWords = words.map((word) => {
        if (word.length === 0) {
          return word;
        }
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });
      const resultString = capitalizedWords.join(" ");
      return resultString;
    },

    calcularEdad(fechanacimiento) {
      if (!fechanacimiento) return;
      var edad = moment().diff(moment(fechanacimiento), "years");
      return edad;
    },

    convertirEnSiglas(texto) {
      // Convertir todo el texto a mayúsculas
      texto = texto.toUpperCase();

      // Palabras que deben ser ignoradas
      const ignorarPalabras = ["DE", "Y"];

      // Separar el texto en palabras
      const palabras = texto.split(/[\s,]+/); // Separar por espacios o comas

      // Filtrar las palabras que no están en la lista de palabras a ignorar
      const siglas = palabras
        .filter((palabra) => !ignorarPalabras.includes(palabra))
        .map((palabra) => palabra.charAt(0)) // Obtener la primera letra de cada palabra
        .join(""); // Unir todas las letras para formar las siglas

      return siglas;
    },
  },
};
