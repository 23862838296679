import Module from './Module.vue';


const moduleRoute = {
    path: "/",
    component: Module,
    children: [
        {
            path: "login",
            component: () => import('@/modules/Usuarios/views/LoginView.vue'),
            name: "login",
            meta: {
                requiresGuest: true,
                title: 'INICIAR SESIÓN'
            }
        },
        {
            path: "usuarios",
            component: () => import('@/modules/Usuarios/views/ManagerUsersView.vue'),
            children: [
                {

                    path: "",
                    components: {
                        manageruser: () => import('./components/ListUsersComponent.vue'),
                    },
                    name: "list-users",
                    meta: {
                        requiresAuth: true,
                        title: 'USUARIOS'
                    }

                }
            ]

        },
        {
            path: "roles-usuario",
            component: () => import('@/modules/Usuarios/views/ManagerUsersView.vue'),
            children: [
                {

                    path: "",
                    components: {
                        manageruser: () => import('./components/ListRolesUserComponent.vue'),
                    },
                    name: "list-roles",
                    meta: {
                        requiresAuth: true,
                        title: 'ROLES DE USUARIO'
                    }

                }
            ]

        }
    ]
};



export default router => {
    router.addRoute(moduleRoute);
};