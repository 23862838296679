<template>
    <v-row class="justify-center" dense>
        <v-col cols="12" lg="9" style="height: calc(-145px + 100vh);" class="d-flex align-center justify-center">
            <v-card elevation="0">
                <v-card-text>

                    <div class="d-flex align-center justify-space-between">
                        <v-img :src="require('@/assets/logo-clinica.png')"
                            :lazy-src="require('@/assets/logo-clinica.png')" :max-width="150" class="mr-4"></v-img>


                        <div class="font-weight-bold text-h5 text-blue-darken-2">
                            Confirmación de Registro de Reclamo
                        </div>

                        <v-img :src="require('@/assets/susalud.png')" :lazy-src="require('@/assets/logo-clinica.png')"
                            :max-width="150" class="mr-4"></v-img>
                    </div>

                    <div class="text-subtitle-1">
                        <p>Estimado(a) {{ reclamoRegistrado.is_paciente_reclamante == 'si' ?  reclamoRegistrado.nombre_completo_paciente : reclamoRegistrado.nombre_completo_reclamante  }},</p>
                        <p>Hemos recibido su reclamo satisfactoriamente. Una copia de su reclamo ha sido enviada al
                            correo electrónico registrado. Nos pondremos en contacto con usted a la brevedad para
                            brindarle la atención necesaria.
                        </p>
                        <p>
                            Gracias por su comprensión y confianza.
                        </p>
                      
                    </div>

                    <div class="text-center" @click="generarPDF(reclamoRegistrado.idreclamo)" style="cursor: pointer;">
                        <v-img :src="require('@/assets/pdf.png')" :width="70" class="mx-auto"></v-img>
                        <div class="pa-2"> Descargar PDF</div>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('libro/RegistrarReclamo', ['reclamoRegistrado'])
    },

    methods:{
        ...mapActions('libro/RegistrarReclamo', ['generarPDF'])
    }
}

</script>