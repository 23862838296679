<template>
    <v-app-bar density="compact" color="#1565C0" elevation="2" style="border-bottom: 5px solid #0D47A1;">
        <v-container class="d-flex text-blue-darken-3">
            <v-app-bar-title></v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'libroreclamacionesnew' }"  variant="text" class="font-weight-bold mx-2 text-white">
                <v-icon left >mdi-book-open-page-variant-outline</v-icon>
                <span class="ml-2">LIBRO RECLA.</span>
            </v-btn>
            <v-btn v-if="!getterLogginIn" :to="{ name: 'login' }"  variant="text" class="font-weight-bold text-white">
                <v-icon left class="mr-2">mdi-desktop-classic</v-icon>
                <span>INTRANET</span>
            </v-btn>
            <v-btn v-if="getterLogginIn" :to="{ name: 'lista-reclamos' }"  variant="text" class="font-weight-bold text-white">
                <v-icon left class="mr-2">mdi-format-list-bulleted-square</v-icon>
                <span>LISTA DE RECLAMOS</span>
            </v-btn>
            <v-btn v-if="getterLogginIn && $abillity.can('read', 'usuarios-area')" :to="{ name: 'list-users' }"  variant="text" class="font-weight-bold text-white">
                <v-icon left class="mr-2 ml-2">mdi-desktop-classic</v-icon>
                <span>USUARIOS</span>
            </v-btn>


            <v-menu v-if="getterLogginIn" v-model="menu" :close-on-content-click="false" location="end">
                <template v-slot:activator="{ props }">
                    <v-avatar  class="bg-white ml-2" v-bind="props" style="cursor: pointer;">
                         A
                    </v-avatar>
                </template>

                <v-card min-width="300">
                    <v-list>
                        <v-list-item  :title="user.nombres + ' ' + user.apellidos"
                            :subtitle="user.cargo">
                            <template v-slot:prepend>
                                <v-avatar>
                                    <v-img :src="require('@/assets/usuario.png')"></v-img>
                                </v-avatar>
                            </template>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" variant="text" @click="logout()">
                            CERRAR SESIÓN
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>

        </v-container>
    </v-app-bar>
</template> 

<script>
import { mapGetters, mapState, mapActions } from 'vuex';


export default {
    data(){
        return{
            menu: false
        }
    },
    computed: {
        ...mapGetters(['getterLogginIn']),
        ...mapState(['user']),

    },

    methods:{
        ...mapActions('usuarios/Auth', ['logout'])
    },

    mounted(){
        console.log(this.respuesta)
    }
}

</script>