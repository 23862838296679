import Module from './Module.vue';


const moduleRoute = {
    path: "/intranet",
    component: Module,
    children: [
        {
            path: "reclamacion",
            component: () => import('@/modules/Libro/views/ManageComplaints.vue'),
            name: "libro-manage",
            meta:{
                title: "Libro de reclamación",
                
            }
        },
        {
            path: "lista-reclamos",
            component: () => import('@/modules/Libro/views/ListComplaints.vue'),
            name: "lista-reclamos",
            meta:{
                title: "Lista de reclamos",
                requiresAuth: true
            }
        }
    ]
};



export default router => {
    router.addRoute(moduleRoute);
}; 