import RegistrarReclamo from "./RegistrarReclamo"
import ResponderReclamacion from "./ResponderReclamacion"


export default{
    modules:{
        ResponderReclamacion,
        RegistrarReclamo
    },
    namespaced:true,
    state:{
        libro:{
            nombre: ""
        }
    }
}