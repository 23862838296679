export default{
    namespaced: true,
    state:{
        component: "", 
        silderRight: false,
        width: 400,
        propscomponent: null
    },
    mutations:{
        switchSilder(state, props = { component: null, width: 400, propscomponent: null}){
            state.silderRight = !state.silderRight
            state.component = props.component
            state.width = props.width
            state.propscomponent = props.propscomponent
        },
        close(state){
            state.silderRight = false
        }
    }
}