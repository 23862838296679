import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";

export default class UserService{
    constructor(){
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers:{
                Accept: "application/json",
                'Content-Type' : "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }


    async get(){
        const response = await this.axios.get('/users')
        return response.data
    }

    
    async save(data){
        const response = await this.axios.post('/user', data)
        return response.data
    }


    async update(data)
    {
        const response = await this.axios.put('/user', data)
        return response.data
    }

    async delete(iduser)
    {
        
        return await this.axios.delete('/user?iduser='+iduser)
    }



}