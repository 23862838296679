<template>
    <v-row class="justify-center" dense>
        <v-col cols="12" md="10" lg="9">
            <div class="font-weight-bold text-subtitle-2 text-center">
                Conforme a lo establecido en el Código de Protección y Defensa del Consumidor este establecimiento
                cuenta con un Libro de Reclamaciones a su disposición.
            </div>
        </v-col>

        <v-col cols="12" md="10" lg="9" class="px-0 px-lg-4">
            <v-card elevation="0">
                <v-card-text>
                    <div v-if="$vuetify.display.mdAndDown">
                        <div class="d-flex align-center justify-space-between">
                            <v-img :src="require('@/assets/logo-clinica.png')"
                                :lazy-src="require('@/assets/logo-clinica.png')" :max-width="120" class="mr-4"></v-img>
                            <v-img :src="require('@/assets/susalud.png')"
                                :lazy-src="require('@/assets/logo-clinica.png')" :max-width="120" class="mr-4"></v-img>
                        </div>
                        <div class="font-weight-bold text-h6 text-center text-blue-darken-2 pb-3">
                            LIBRO DE RECLAMACIONES
                        </div>
                    </div>
                    <div class="d-flex align-center justify-space-between" v-if="$vuetify.display.lgAndUp">
                        <v-img :src="require('@/assets/logo-clinica.png')"
                            :lazy-src="require('@/assets/logo-clinica.png')" :max-width="150" class="mr-4"></v-img>


                        <div class="font-weight-bold text-h5 text-blue-darken-2">
                            LIBRO DE RECLAMACIONES
                        </div>

                        <v-img :src="require('@/assets/susalud.png')" :lazy-src="require('@/assets/logo-clinica.png')"
                            :max-width="150" class="mr-4"></v-img>
                    </div>
                    <div
                        class="bg-blue-darken-2 text-body-2 d-flex flex-column px-9 font-weight-medium py-4 rounded-lg">
                        <span>Razón Social: CLINICA ZORIZUFE S.A.C</span>
                        <span>RUC: 20554121200</span>
                        <span>Domicilio Fiscal: Jr. Chucuito Nro. 316 (a 2 Cuadras de Plaza de Armas de Chosica)</span>
                    </div>

                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12" md="10" lg="9" class="px-0 px-lg-4">
            <v-card elevation="0" class="pa-lg-3" :disabled="loadingSave">
                <v-card-text>

                    <div class="font-weight-bold text-title text-left mb-6">1. IDENTIFICACIÓN DEL USUARIO (PACIENTE) O TERCERO LEGITIMADO</div>
                    <v-form v-model="isValidForm">
                        <v-row>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Tipo de documento <b
                                        class="text-red font-weight-bold">(*)</b>
                                </div>
                                <v-select :items="tipoDocumento" density="compact" variant="outlined"
                                    class="font-weight-bold" :rules="[rules.requiredSelect]"
                                    v-model="reclamo.tipo_documento_paciente" hide-details="auto"></v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">N° de documento <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.numero_documento_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" class="py-2">
                                <div class="mb-2 text-left">Nombre completo o Razón Social <b
                                        class="text-red font-weight-bold ">(*)</b></div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.nombre_completo_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Teléfono fijo 
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                     v-model="reclamo.telefono_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Celular <b class="text-red font-weight-bold ">(*)</b></div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.celular_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Correo electrónico <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required, rules.email]" v-model="reclamo.email_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Dirección <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.direccion_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Departamento <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.departamento_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Provincia <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.provincia_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Distrito <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.distrito_paciente"
                                    hide-details="auto"></v-text-field>
                            </v-col>


                        </v-row>
                        <div class="font-weight-bold text-title text-left my-6">2. IDENTIFICACIÓN DE QUIÉN PRESENTA EL
                            RECLAMO
                        </div>
                        <v-row>
                            <v-col cols="12" class="py-2 d-flex align-center">
                                <div class="mb-2 text-left text-subtitle-1">En caso de ser el usuario afectado marque
                                    aquí</div>
                                <v-checkbox density="compact" v-model="reclamo.is_paciente_reclamante"
                                    class="ma-0 mb-1 ml-2" hide-details="auto" true-value="si"
                                    false-value="no"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="reclamo.is_paciente_reclamante === 'no'">
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Tipo de relación <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-select :items="parentesco" density="compact" variant="outlined"
                                    class="font-weight-bold" hide-details="auto" :rules="[rules.requiredSelect]"
                                    v-model="reclamo.tipo_relacion_reclamante"></v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Tipo de documento <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-select :items="tipoDocumento" density="compact" variant="outlined"
                                    class="font-weight-bold" :rules="[rules.requiredSelect]"
                                    v-model="reclamo.tipo_documento_reclamante" hide-details="auto"></v-select>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">N° de documento <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.numero_documento_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12" class="py-2">
                                <div class="mb-2 text-left">Nombre completo <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.nombre_completo_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Dirección <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.direccion_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Departamento <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.departamento_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Provincia <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.provincia_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Distrito <b class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.distrito_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Correo electrónico <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required, rules.email]" v-model="reclamo.email_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Teléfono fijo 
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                     v-model="reclamo.telefono_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Celular <b class="text-red font-weight-bold ">(*)</b></div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    :rules="[rules.required]" v-model="reclamo.celular_reclamante"
                                    hide-details="auto"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-2 d-flex align-center">
                                <div class="mb-2 text-left text-subtitle-1 mr-2">Autorizo Notificación del Resultado al
                                    email consignado <b class="text-red font-weight-bold ">(*)</b></div>
                                <v-radio-group inline density="compact" :rules="[rules.required]"
                                    v-model="reclamo.autoriza_uso_email" hide-details="auto" class="ma-0">
                                    <v-radio label="Si" value="si"></v-radio>
                                    <v-radio label="No" value="no"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <div class="font-weight-bold text-title text-left my-6">3. DETALLE DEL RECLAMO</div>
                        <v-row>
                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Fecha de Ocurrencia <b
                                        class="text-red font-weight-bold ">(*)</b></div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    hide-details="auto" :rules="[rules.required]" v-model="reclamo.fecha_ocurrencia"
                                    type="date"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Hora de atención
                                </div>
                                <v-text-field density="compact" variant="outlined" class="font-weight-bold"
                                    hide-details="auto" v-model="reclamo.hora_ocurrencia" type="time"></v-text-field>
                            </v-col>


                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Sede de ocurrencia <b
                                        class="text-red font-weight-bold ">(*)</b>
                                </div>
                                <v-select density="compact" :rules="[rules.required]" v-model="reclamo.sede_ocurrencia"
                                    variant="outlined" class="font-weight-bold" hide-details="auto"
                                    :items="sedes"></v-select>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Tipo Producto/Servicio<b
                                        class="text-red font-weight-bold ">(*)</b></div>
                                <v-select density="compact" :rules="[rules.requiredSelect]"
                                    :items="tipoProductoServicio" v-model="reclamo.tipo_producto_servicio"
                                    variant="outlined" class="font-weight-bold" hide-details="auto"></v-select>
                            </v-col>

                            <v-col cols="12" md="6" lg="4" class="py-2">
                                <div class="mb-2 text-left">Área donde fue atendido<b
                                    class="text-red font-weight-bold ">(*)</b></div>
                                <v-select density="compact" :rules="[rules.required]"
                                    v-model="reclamo.area_atencion" variant="outlined" class="font-weight-bold"
                                    hide-details="auto" :items="areas"></v-select>
                            </v-col>



                            <v-col cols="12" class="py-2">
                                <div class="mb-2 text-left">Detalle del reclamo/queja <b
                                        class="text-red font-weight-bold ">(*)</b></div>
                                <v-textarea density="compact" :rules="[rules.required]"
                                    v-model="reclamo.detalle_reclamo" variant="outlined" class="font-weight-bold"
                                    hide-details="auto"></v-textarea>
                            </v-col>


                            <v-col cols="12" class="py-2">
                                <v-checkbox color="black" :rules="[rules.sirequired]" hide-details
                                    v-model="reclamo.confirmacion_datos" style="opacity: 1;" true-value="si"
                                    false-value="no">
                                    <template v-slot:label>
                                        <div class="text-black font-weight-medium">
                                            He leído y doy conformidad a mis datos y garantizo la veracidad del reclamo
                                            o
                                            queja.
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-start">
                                <v-btn :disabled="!isValidForm" @click="registrarReclamo()" elevation="0"
                                    color="blue-accent-4" size="large" class="rounded-0 font-weight-bold">ENVIAR</v-btn>
                            </v-col>

                        </v-row>
                    </v-form>

                </v-card-text>
            </v-card>
        </v-col>


        <v-col cols="12" md="10" lg="9">
            <div class="text-subtitle-2">
                Estimados usuarios, después de enviar el reclamo, recibirán una copia del mismo en el correo electrónico
                proporcionado. Si no ven la respuesta en su bandeja de entrada, revisen la carpeta de "Correo no
                deseado",
                "Spam" o "Bulk", según el servicio de correo que utilicen, y márquenlo como "Correo Seguro".
            </div>
        </v-col>

        <v-col cols="12" md="10" lg="9">
            <v-card elevation="0" class="bg-blue-grey-lighten-4">
                <v-card-text>
                    <div class="text-subtitle-2">
                        Las IAFAS, IPRESS o UGIPRESS deben atender el reclamo en plazo de 30 días hábiles.
"Estimado usuario: Usted puede presentar su queja ante SUSALUD cuando no le hayan brindado un servicio, prestación o cobertura solicitada, o recibida de las IAFAS o IPRESS, o que dependan de las UGIPRESS públicas, privadas o mixtas. También ante la negativa de atención de su reclamo, irregularidad en su tramitación o disconformidad con el resultado del mismo".
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-overlay v-model="loadingSave" scrim="white" class="d-flex align-center justify-center overlayCustom">
        <v-card elevation="0">
            <v-card-text>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <v-img :src="require('@/assets/logo-clinica.png')"
                            :lazy-src="require('@/assets/logo-clinica.png')" :max-width="150"
                            class="mb-2 mx-auto"></v-img>
                        <div class="text-subtitle-1 text-center">Enviando solicitud de reclamo</div>
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear color="blue-lighten-1" height="6" indeterminate rounded></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-overlay>
</template>


<script>
import filters from '@/modules/Shared/filters';
import { mapState, mapActions, mapMutations } from 'vuex';


export default {
    mixins: [filters],
    data() {
        return {
            isValidForm: true,
            overlay: true,
            parentesco: [
                "Madre/Padre",
                "Esposo(a)",
                "Hermano(a)",
                "Hijo(a)",
                "Nieto(a)",
                "Tio(a)",
                "Representante Legal",
                "Amistad",
                "Abuelo",
                "Familiar",
                "Jefe(a)",
                "Suegro(a)",
                "Yerno/Nuera",
                "Sobrino(a)",
                "Anónimo",
                "Ahijado(a)",
                "Primo(a)"
            ],
            tipoDocumento: [
                "DNI",
                "CARNÉ DE EXTRANJERÍA",
                "PASAPORTE",
                "DOCUMENTO DE IDENTIDAD EXTRANJERO",
                "CODIGO UNICO DE IDENTIFICACION (CUI)",
                "CERTIFICADO DE NACIDO (vivo)",
                "PERMISO TEMPORAL DE PERMANECIA",
                "RUC",
                "OTROS"
            ],

            tipoProductoServicio: [
                "Producto",
                "Servicio",
                "Producto y Servicio"
            ],

            sedes: [
                "Chucuito Nro. 316 - Chosica"
            ],

            areas: [
            "Cardiología", 
            "Cirugía general",
            "Dermatología",
            "Endocrinología",
            "Gastroenterología",
            "Ginecología",
            "Medicina",
            "Medicina interna",
            "Medicina física",
            "Neumología",
            "Neurología",
            "Nutrición",
            "Obstetricia",
            "Odontología",
            "Oftalmología",
            "Oncología medica",
            "Otorrinolaringóloga",
            "Pediatría",
            "Podología",
            "Psicología",
            "Reumatología",
            "Terapia física y rehabilitación", 
            "Terapia de piso pélvico",
            "Traumatología",
            "Urología",
            "Rayos X digital",
            "Mamografía digital",
            "Densitometría ósea ",
            "Ecografía general",
            "Ecografía gineco-obstétrica",
            "Anatomía patológica",
            "Laboratorio Clínico",
            "EKG / MAPA / Holter",
            "Farmacia", 
            "Tópico de enfermería e inyectables",
            "Hospitalización", 
            "Sala de operaciones",
            ]
        }
    },

    computed: {
        ...mapState('libro/RegistrarReclamo', ['reclamo', 'loadingSave'])
    },

    methods: {
        ...mapActions('libro/RegistrarReclamo', ['registrarReclamo']),
        ...mapMutations('libro/RegistrarReclamo', ['resetReclamo']),
    },

    mounted() {
        this.resetReclamo()
    }
}
</script>