import utiles from "@/modules/Shared/utiles";
import store from "@/store";
import axios from "axios";


export default class RolService {
    constructor() {
        this.axios = axios.create({
            baseURL: utiles.state.api,
            headers: {
                Accept: "application/json",
                'Content-Type': "application/json",
                Authorization: "Bearer " + store.state.user.token
            }
        });

        store.watch(
            (state) => state.user,
            (newUser) => {
              this.axios.defaults.headers.Authorization = `Bearer ${newUser.token}`;
            }
          );
    }

    async getAll() {
        const response = await this.axios.get('/rolespermisos')
        return response.data
    }

    async createRole(data) {
        const response = await this.axios.post('/rolespermisos', data)
        return response.data
    }

    async updateRole(data) {
        const response = await this.axios.put('/rolespermisos', data)
        return response.data
    }

    async deleteRole(id) {
        return await this.axios.delete('/rolespermisos?idrole=' + id)
    }

    async roleDefault(id){
        return await this.axios.post('roledefault', { idrole: id })
    }
}