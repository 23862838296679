import Swal from "sweetalert2";
import RegistrarReclamoService from "../services/registrarReclamoService.js";

const service = new RegistrarReclamoService();
export default {
  namespaced: true,
  state: {
    sendReclamo: "FormLibro",
    reclamo: {
      tipo_documento_paciente: "",
      numero_documento_paciente: "",
      nombre_completo_paciente: "",
      telefono_paciente: "",
      celular_paciente: "",
      email_paciente: "",
      direccion_paciente: "",
      departamento_paciente: "",
      provincia_paciente: "",
      distrito_paciente: "",
      is_paciente_reclamante: "no",
      tipo_relacion_reclamante: "",
      tipo_documento_reclamante: "",
      numero_documento_reclamante: "",
      nombre_completo_reclamante: "",
      telefono_reclamante: "",
      celular_reclamante: "",
      email_reclamante: "",
      direccion_reclamante: "",
      departamento_reclamante: "",
      provincia_reclamante: "",
      distrito_reclamante: "",
      autoriza_uso_email: "",
      fecha_ocurrencia: null,
      hora_ocurrencia: null,
      sede_ocurrencia: "",
      tipo_producto_servicio: "",
      area_atencion: "",
      detalle_reclamo: "",
      confirmacion_datos: "",
    },

    reclamoDefault: {
      tipo_documento_paciente: "",
      numero_documento_paciente: "",
      nombre_completo_paciente: "",
      telefono_paciente: "",
      celular_paciente: "",
      email_paciente: "",
      direccion_paciente: "",
      departamento_paciente: "",
      provincia_paciente: "",
      distrito_paciente: "",
      is_paciente_reclamante: "no",
      tipo_relacion_reclamante: "",
      tipo_documento_reclamante: "",
      numero_documento_reclamante: "",
      nombre_completo_reclamante: "",
      telefono_reclamante: "",
      celular_reclamante: "",
      email_reclamante: "",
      direccion_reclamante: "",
      departamento_reclamante: "",
      provincia_reclamante: "",
      distrito_reclamante: "",
      autoriza_uso_email: "",
      fecha_ocurrencia: null,
      hora_ocurrencia: null,
      sede_ocurrencia: "",
      tipo_producto_servicio: "",
      area_atencion: "",
      detalle_reclamo: "",
      confirmacion_datos: "",
    },
    reclamoRegistrado:{},
    filterReclamo: {
      fecha_reclamo: "",
      dni_reclamante: "",
      dni_paciente: "",
      codigo: "",
      tipo_producto_servicio: "",
      estado_reclamo: ""
    },

    respuestaReclamo: {
      dialog: false,
      detalle_cierre_reclamo: "",
       idreclamo: ""
    },
    resetReclamoDefault:{
      dialog: false,
      detalle_cierre_reclamo: "",
      idreclamo: ""
    },

    listaReclamos: [],
    loadingLista: false,
    loadingSave: false,
    loadingEstado: false
  },

  mutations: {
    setReclamo(state, reclamo) {
      state.reclamo = Object.assign({}, reclamo);
    },

    resetReclamo(state) {
      state.reclamo = Object.assign({}, state.reclamoDefault);
    },

    setListaReclamos(state, payload) {
      state.listaReclamos = payload;
    },

    setReclamoRegistrado(state, payload)
    {
      state.reclamoRegistrado = Object.assign({}, payload)
    },

    resetReclamoRegistrado(state)
    {
      state.reclamoRegistrado = {}
    },

    resetRespuestaReclamo(state)
    {
      state.resetReclamo = Object.assign({}, state.resetReclamoDefault)
    }
  },

  actions: {
    async getReclamos({ state, commit }) {
      state.loadingLista = true;
      try {
        const data = await service.get(state.filterReclamo);
        state.loadingLista = false;
        console.log(data)
        commit("setListaReclamos", data);
      } catch (error) {
        console.log(error);
        state.loadingLista = false;
      }
    },

    async registrarReclamo({ state, dispatch, commit }) {
      state.loadingSave = true
      try {
        const data = await service.create(state.reclamo);
        dispatch("getReclamos");
        commit("resetReclamo")
        commit("setReclamoRegistrado", data)
        state.loadingSave = false
        state.sendReclamo = "ConfirmacionEnvio"
      } catch (error) {
        console.log(error);
        state.loadingSave = false
        state.sendReclamo = "FormLibro"

      }
    },

    async cambiarEstado({ state, dispatch, commit }, payload) {
      state.loadingEstado = true
      try {
        const data = await service.cambiarEstado(payload);
        commit('setReclamo', data)
        dispatch("getReclamos");
        state.loadingEstado = false
      } catch (error) {
        console.log(error);
        state.loadingEstado = false
      }
    },

    async cerrarReclamo({ state, dispatch, commit }) {

        // Código para cerrar el reclamo
        state.loadingEstado = true;
        try {
          const data = await service.cerrarReclamo(state.respuestaReclamo);
          state.respuestaReclamo.dialog = false
          commit('setReclamo', data);
          dispatch("getReclamos");
          commit('resetRespuestaReclamo')
        } catch (error) {
          console.error(error);
          state.loadingEstado = false;
        } finally {
          state.loadingEstado = false;
        }
  
    },
    

    async generarPDF({ state, dispatch, commit }, idreclamo) {
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor, espere un momento.',
        icon: 'info',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      try {
        const data = await service.generarPDF({idreclamo: idreclamo});
        Swal.fire({
          title: "Se generó el pdf.",
          icon: "success"
        })
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Ha ocurrido un error",
          icon: "error"
        })
      }
    },
  },
};
