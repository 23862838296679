import UserService from "../services/UserService"
import AuthService from "../services/AuthSevice";
import utiles from "@/modules/Shared/utiles";
import store from "@/store";

const UserSV = new UserService;
const AuthSV = new AuthService;
export default {
    namespaced: true,
    modules: {
        utiles
    },
    state: {
        user: {
            nombres: "",
            apellidos: "",
            email: "",
            password: "",
            tipo_documento: "",
            numero_documento: "",
            cargo: "",
            area: "",
            celular: "",
            role_id: null
        },
        userDefault: {
            nombres: "",
            apellidos: "",
            email: "",
            password: "",
            tipo_documento: "",
            numero_documento: "",
            cargo: "",
            area: "",
            celular: "",
            role_id: null
        },

        filter:
        {
            buscar: ""
        },

        listUsers: [],
        loadingRegister: false,
        loadingUser: false
    },

    mutations: {
        resetUser(state) {
            state.user = Object.assign({}, state.userDefault)
        },

        setListUsers(state, payload) {
            state.listUsers = payload
        },
        editUser(state, payload) {
            state.user = Object.assign({}, payload);
        },

        pushUser(state, payload) {
            state.user = Object.assign({}, payload)
        }

    },
    actions: {



        async showUser({ commit, state }, iduser) {
            state.loadingShowUser = true
            try {
                const data = await AuthSV.showUser(iduser)
                commit('pushUser', data)
                state.loadingShowUser = false
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
                state.loadingShowUser = false

            }
        },



        async getUsers({ state, commit }) {
            state.loadingUser = true
            try {
                const data = await UserSV.get()
                commit('setListUsers', data)
                state.loadingUser = false
            }
            catch (error) {
                console.log(error)
                state.loadingUser = false
            }
        },



        async saveUser({ commit, dispatch, state }) {
            state.loadingRegister = true
            try {
                const data = await UserSV.save(state.user)
                dispatch('getUsers')
                commit('resetUser')
                state.loadingRegister = false
                store.commit('silderRight/close')
            }
            catch (error) {
                console.log(error)
                state.loadingRegister = false

            }
        },

        async updateUser({ commit, dispatch, state }) {
            state.loadingRegister = true
            try {
                const data = await UserSV.update(state.user)
                commit('resetUser')
                dispatch('getUsers')
                state.loadingRegister = false
                store.commit('silderRight/close')
            }
            catch (error) {
                console.log(error)
                state.loadingRegister = false
            }

        },


        async switchActiveUser({ commit, dispatch, state }, iduser) {
            commit('utiles/alertLoading')
            try {
                await AuthSV.switchActiveUser(iduser);
                dispatch('getUsers')
                commit('utiles/alertConfirm', 'Se ha realizado el cambio')
            }
            catch (error) {
                console.log(error)
                commit('utiles/alertError')
            }
        }


    }
}