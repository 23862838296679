import silderRight from "@/modules/Shared/store/silderRight"
import Auth from "./Auth"
import RegisterUser from "./RegisterUser"
import roles from "./roles"

export default{
    namespaced:true,
    modules:{
        Auth,
        silderRight,
        RegisterUser,
        roles
    },
    state:{}
}